/* About.css */

.about-container {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #000;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.about-header {
    text-align: center;
    margin-bottom: 40px;
}

.about-header h2 {
    font-size: 3rem;
    color: #FFF;
    margin-bottom: 10px;
    font-weight: 700;
}

.about-header p {
    font-size: 1.2rem;
    color: #aaa;
    max-width: 700px;
    margin: 0 auto;
    line-height: 1.6;
}

.about-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.about-section {
    background-color: #1a1a1a;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.05);
}

.about-section h3 {
    font-size: 2rem;
    color: #FFF;
    margin-bottom: 20px;
    font-weight: 600;
}

.about-section p {
    font-size: 1.1rem;
    color: #ccc;
    line-height: 1.8;
}

.about-section .highlight {
    color: #007bff;
    font-weight: 600;
}

.values-list {
    padding-left: 20px;
    list-style: none;
}

.values-list li {
    font-size: 1.1rem;
    color: #ddd;
    margin-bottom: 10px;
}

.values-list strong {
    color: #fff;
}

.team-section {
    text-align: center;
}

.team-members {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.team-member {
    max-width: 200px;
}

.team-image {
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    margin-bottom: 10px;
}

.team-member h4 {
    font-size: 1.3rem;
    color: #FFF;
    margin-bottom: 5px;
    font-weight: 600;
}

.team-member p {
    font-size: 1rem;
    color: #aaa;
}
