.rooms {
    padding: 10px;
    background-color: #1C1C1C;
    color: #E0E0E0;
    border-radius: 8px;
    border: 1px solid #333;
}

.rooms .room-card {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.rooms .room-card img {
    width: 200px; /* Reduced image size */
    height: auto;
    border-radius: 8px;
}

.rooms .room-card h3 {
    font-size: 1.4rem;
    color: #E0E0E0;
}

.rooms .room-card p {
    font-size: 1rem;
    color: #A9A9A9;
    line-height: 1.5;
}

/* VIP Rooms Section */
.rooms .vip-room {
    border: 2px solid gold;
    padding: 15px;
    background-color: #000;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
}

/* Premium Rooms Section */
.rooms .premium-room {
    border: 2px solid gold;
    padding: 15px;
    background-color: #000;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(192, 192, 192, 0.3);
}
