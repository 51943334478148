
.home {
    font-family: 'Arial', sans-serif;
    color: #f0f0f0;
    background-color: #000;
    margin: 0;
    padding: 0;
}

.home-hero {
    background: url('/public/hotel.png') cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    padding: 0 20px;
}

.hero-content {
    max-width: 600px;
    margin: 0 auto;
}

.hero-content h1 {
    font-size: 4rem;
    margin: 0;
    color: #ffffff;
}

.hero-content p {
    font-size: 1.5rem;
    margin: 20px 0;
    color: #dcdcdc;
}

.cta-button {
    background-color: #000;
    color: #ffffff;
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #111;
}



.home-services {
    padding: 60px 20px;
    text-align: center;
    background-color:black;
}

.home-services h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #ecf0f1;
}

.service-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.service-card {
    background-color: #000 ;
    border-radius: 8px;
    padding: 20px;
    width: 250px;
    text-align: center;
    color: #ecf0f1;
    transition: background-color 0.3s ease;
}

.service-card:hover {
    background-color: #333;
}

.service-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.service-card h3 {
    font-size: 1.5rem;
    margin: 10px 0;
}

.service-card p {
    font-size: 1rem;
    margin: 0;
}
